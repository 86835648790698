<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @open="getAddresses"
        @closed="closeModal"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.add_key') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-12 px-10">
            <div class="flex justify-center">
                <el-upload
                    ref="upload"
                    action="#"
                    :class="{ 'hide-new-file': file }"
                    list-type="picture-card"
                    :on-change="imageHandleChange"
                    :on-remove="imageHandleRemove"
                    :multiple="false"
                    accept=".jpg,.png,.jpeg"
                    :limit="1"
                    :auto-upload="false"
                >
                    <i slot="default" class="el-icon-camera" />
                    <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-delete"
                                @click="imageHandleRemove"
                            >
                                <i class="el-icon-delete" />
                            </span>
                        </span>
                    </div>
                </el-upload>
            </div>

            <el-form ref="form" :model="formData" label-position="top" :rules="rules">
                <el-form-item :label="$t('clients.number')" prop="name">
                    <el-input v-model="formData.name" />
                </el-form-item>

                <el-form-item :label="$t('clients.description')">
                    <el-input
                        v-model="formData.description"
                        type="textarea"
                        :rows="3"
                    />
                </el-form-item>

                <el-form-item :label="$t('clients.address')" prop="addressUuid">
                    <el-select v-model="formData.addressUuid" :placeholder="$t('client.address')" class="w-full">
                        <el-option
                            v-for="address in addressesToDropdownData"
                            :key="address.uuid"
                            :label="address.street"
                            :value="address.uuid"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.add_key')" @click="addKey">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './keys.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        clientUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            file:                    null,
            addressesToDropdownData: [],
            formData:                {
                name:        null,
                addressUuid: null,
                description: null,
            },
            rules: {
                name:        [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                addressUuid: [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
            },
        };
    },

    methods: {
        async getAddresses() {
            this.addressesToDropdownData = await Api.getAllAddresses(this.clientUuid);
        },

        async addKey() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.add_key');

            try {
                this.$wait.start('loading.add_key');
                const data = await Api.createKey(this.formData);

                await this.uploadImage(data);

                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_key');
            }
        },

        imageHandleChange(file) {
            this.file = file;
        },

        async uploadImage(keyUuid) {
            if (!this.file) return;
            try {
                const formData = new FormData();
                formData.append('file', this.file.raw);

                await Api.uploadImage(keyUuid, formData);
            } catch {
                console.log('Error while uploading image');
            }
        },

        imageHandleRemove() {
            this.$refs.upload.clearFiles();
            this.file = null;
        },

        closeModal() {
            this.clearData();
            this.$emit('close');
        },

        clearData() {
            this.$refs.form.resetFields();
            this.$refs.upload.clearFiles();
            this.file = null;
            this.formData = {
                name:        null,
                addressUuid: null,
                description: null,
            };
        },
    },
};
</script>
<style>
.hide-new-file .el-upload {
    display: none !important;
}
</style>
